'use client';

import * as React from 'react';
import classNames from 'classnames';
import ArticleCard, { IArticleCardProps } from '@/components/ui/ArticleCard';
import styles from './styles.module.scss';

export interface IListArticlesProps {
  items: IArticleCardProps[];
  layout?: 'default' | 'compact';
  newTab?: boolean;
  loadingType?: 'lazy' | 'eager';
  dataLoaded?: boolean;
}

const ListArticles: React.FunctionComponent<IListArticlesProps> = (props) => {
  if (props.items.length === 0) {
    return null;
  }
  return (
    <ul
      className={classNames(styles['list-articles'], {
        [styles['layout-default']]: props.layout === 'default' || !props.layout,
        [styles['layout-compact']]: props.layout === 'compact',
      })}
      data-ready={props.dataLoaded}
    >
      {props.items.map((item) => (
        <li key={item.id}>
          <ArticleCard
            {...item}
            newTab={props.newTab}
            loadingType={props.loadingType}
          />
        </li>
      ))}
    </ul>
  );
};

ListArticles.displayName = 'ListArticles';

export default ListArticles;
