'use client';

import classNames from 'classnames';
import * as React from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import styles from './styles.module.scss';

export interface IPaginatorProps {
  /** Number of pages */
  numberOfPages: ReactPaginateProps['pageCount'];
  /** Currently selected page number (starts with 1) */
  currentPage: ReactPaginateProps['pageRangeDisplayed'];
  /** The method to call when a page is clicked. Exposes the current page object as an argument. */
  onPageChange: (selectedItem: { selected: number }) => void;
  /** Previous button label */
  previousLabel?: string;
  /** Next button label */
  nextLabel?: string;
  /** Render without button labels */
  compact?: boolean;
}

/**
 * Renders a paginator or null under a table.
 */
const Paginator: React.FunctionComponent<IPaginatorProps> = (props) => {
  if (props.numberOfPages < 2) {
    return null;
  }
  return (
    <ReactPaginate
      pageCount={props.numberOfPages}
      pageRangeDisplayed={7}
      forcePage={(props.currentPage ? props.currentPage : 1) - 1}
      marginPagesDisplayed={1}
      containerClassName={styles['paginator']}
      onPageChange={props.onPageChange}
      className={styles['paginator']}
      disableInitialCallback={true}
      previousLabel={<span>Previous page</span>}
      nextLabel={<span>Next page</span>}
      previousLinkClassName={classNames(styles['arrow'], styles['arrow-left'])}
      nextLinkClassName={classNames(styles['arrow'], styles['arrow-right'])}
    />
  );
};

Paginator.displayName = 'Paginator';

export default Paginator;
