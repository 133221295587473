'use client';

import * as React from 'react';
import classNames from 'classnames';
import Icon from '../Icon';
import styles from './styles.module.scss';

export interface IControlCheckboxProps {
  /** Controlled value */
  checked: React.InputHTMLAttributes<HTMLInputElement>['checked'];
  /** Disabled status */
  disabled?: React.InputHTMLAttributes<HTMLInputElement>['disabled'];
  /** Focus handler */
  onFocus?: React.InputHTMLAttributes<HTMLInputElement>['onFocus'];
  /** Blur handler */
  onBlur?: React.InputHTMLAttributes<HTMLInputElement>['onBlur'];
  /** Change handler */
  onChange?: React.InputHTMLAttributes<HTMLInputElement>['onChange'];
  /** DOM target element name attribute */
  name?: React.InputHTMLAttributes<HTMLInputElement>['name'];
  /** DOM target element id attribute */
  id?: React.InputHTMLAttributes<HTMLInputElement>['id'];
  /** Click handler */
  onClick?: React.InputHTMLAttributes<HTMLInputElement>['onClick'];
  /** Error */
  hasError?: boolean;
  /** className to append */
  className?: string;
  /** input ref */
  inputRef?: React.RefObject<HTMLInputElement>;
}

/**
 * HTML checkbox replacement with extended functionality and styling.
 */
export const ControlCheckbox: React.FunctionComponent<IControlCheckboxProps> = (props) => {
  return (
    <label className={styles['control-checkbox']}>
      <span
        className={classNames(
          styles['control-checkbox__overflow'],
          {
            [styles['has-error']]: props.hasError,
          },
          props.className,
        )}
      >
        <input
          type="checkbox"
          checked={props.checked}
          disabled={props.disabled}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onClick={props.onClick}
          onChange={props.onChange}
          ref={props.inputRef}
          name={props.name}
          id={props.id}
        />
        <span>
          <Icon
            width={16}
            height={16}
            kind="check"
          />
        </span>
      </span>
    </label>
  );
};

ControlCheckbox.displayName = 'ControlCheckbox';

export default ControlCheckbox;
