import * as React from 'react';
import Button, { IButtonProps } from '@/components/ui/Button';
import Animations, { IAnimationsProps } from '@/components/ui/Animations';
import ButtonIcon, { IButtonIconProps } from '@/components/ui/ButtonIcon';
import styles from './styles.module.scss';

export interface IHeadingWithToolsProps {
  title: React.ReactNode;
  pre?: React.ReactNode;
  description?: React.ReactNode;
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  buttons?: IButtonProps[];
  buttonIcons?: IButtonIconProps[];
  animation?: IAnimationsProps['animation'];
  addOn?: React.ReactNode;
}

const HeadingWithTools: React.FunctionComponent<IHeadingWithToolsProps> = (props) => {
  let Elem: keyof React.JSX.IntrinsicElements = 'h1';
  if (props.level) {
    Elem = props.level;
  }
  return (
    <div className={styles['heading-with-tools']}>
      <div className={styles['heading-with-tools__main']}>
        {props.pre && <div className={styles['heading-with-tools__pre']}>{props.pre}</div>}
        <Elem>{props.title}</Elem>
        {props.description && <div className={styles['heading-with-tools__description']}>{props.description}</div>}
      </div>
      {props.addOn && <div className={styles['heading-with-tools__addon']}>{props.addOn}</div>}
      {((props.buttons && props.buttons.length > 0) || (props.buttonIcons && props.buttonIcons.length > 0)) && (
        <div className={styles['heading-with-tools__buttons']}>
          {props.buttons &&
            props.buttons.length > 0 &&
            props.buttons.map((item, itemIndex) => (
              <Button
                key={itemIndex}
                {...item}
              />
            ))}
          {props.buttonIcons &&
            props.buttonIcons.length > 0 &&
            props.buttonIcons.map((item, itemIndex) => (
              <ButtonIcon
                key={itemIndex}
                {...item}
              />
            ))}
        </div>
      )}
      {props.animation && (
        <div className={styles['heading-with-tools__animation']}>
          <Animations animation={props.animation} />
        </div>
      )}
    </div>
  );
};

HeadingWithTools.displayName = 'HeadingWithTools';

export default HeadingWithTools;
