'use client';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useOverlay } from '@/components/hooks/useOverlay';
import Overlay from '@/components/ui/Overlay';
import LayoutOverlay from '@/components/ui/LayoutOverlay';
import Block from '@/components/ui/Block';
import OverlayTitle from '@/components/ui/LayoutOverlay/OverlayTitle';
import ContactForm from '@/components/content/ContactForm';
import NonIdealState from '@/components/ui/NonIdealState';
import { IMarketingListToolFilter } from '@/components/content/MarketingListTool/interfaces/marketingListToolFilter.interface';
import { OverlayKey } from '@/lib/enums/overlayKey.enum';
import { IEmailLabels } from '@/components/content/MarketingListTool';

interface IProps {
  form?: IMarketingListToolFilter;
  isPersonFilter?: boolean;
  formType?: 'MARKETING' | 'BACKGROUND' | 'CONTACT';
  emailLabels?: IEmailLabels;
}

const OverlayAskOffer: React.FC<IProps> = ({ form, isPersonFilter, formType, emailLabels }) => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = React.useState(false);
  const { isOverlayOpen, zIndexOfOverlay, closeOverlay } = useOverlay(OverlayKey.request_offer);
  return (
    <Overlay
      isOpen={isOverlayOpen}
      onClose={closeOverlay}
      layout="default"
      zIndex={zIndexOfOverlay}
      hideClose={true}
    >
      <LayoutOverlay size="default">
        <Block
          width="narrow"
          theme="lighter"
        >
          {submitted ? (
            <React.Fragment>
              <OverlayTitle onClose={closeOverlay} />
              <NonIdealState
                title={t('components.askOffer.send')}
                imageUrl="/img/success.png"
                buttons={[
                  {
                    title: t('components.askOffer.close'),
                    theme: 'primary',
                    onClick: () => {
                      setSubmitted(false);
                    },
                  },
                ]}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <OverlayTitle
                title={t('components.askOffer.title')}
                onClose={closeOverlay}
              />
              <ContactForm
                onCancel={closeOverlay}
                onSubmit={() => setSubmitted(true)}
                selectedForm={form}
                isPersonFilter={isPersonFilter}
                formType={formType}
                emailLabels={emailLabels}
              />
            </React.Fragment>
          )}
        </Block>
      </LayoutOverlay>
    </Overlay>
  );
};

OverlayAskOffer.displayName = 'OverlayAskOffer';

export default OverlayAskOffer;
