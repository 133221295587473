'use client';

import * as React from 'react';
import classNames from 'classnames';
import Icon, { IconsAvailable } from '@/components/ui/Icon';
import Button, { IButtonProps } from '@/components/ui/Button';
import styles from './styles.module.scss';

export interface IDetailsProps {
  prefix?: React.ReactNode;
  title: React.ReactNode;
  titleExpanded?: React.ReactNode;
  children: React.ReactNode;
  defaultOpen?: boolean;
  layout?: 'default' | 'compact';
  buttons?: IButtonProps[];
}

const Details: React.FunctionComponent<IDetailsProps> = (props) => {
  const [open, setOpen] = React.useState(typeof props.defaultOpen !== 'undefined' ? props.defaultOpen : false);

  const handleToggle = (event: { currentTarget: { open: boolean } }) => setOpen(event.currentTarget.open);
  let title = props.title;

  if (props.titleExpanded && open) {
    title = props.titleExpanded;
  }

  let arrows: [IconsAvailable, IconsAvailable] = ['close', 'add'];

  if (props.layout === 'compact') {
    arrows = ['arrow03-up', 'arrow03-down'];
  }

  return (
    <details
      className={classNames(styles['details'], {
        [styles['layout-default']]: props.layout === 'default' || !props.layout,
        [styles['layout-compact']]: props.layout === 'compact',
      })}
      open={open}
      onToggle={handleToggle}
    >
      <summary>
        <Icon
          width={24}
          height={24}
          kind={open ? arrows[0] : arrows[1]}
        />
        <div className={styles['details__text']}>
          {props.prefix && <div className={styles['details__prefix']}>{props.prefix}</div>}
          <div className={styles['details__title']}>{title}</div>
        </div>
        {props.buttons && props.buttons.length > 0 && (
          <div className={styles['details__buttons']}>
            {props.buttons.map((b, bi) => (
              <Button
                key={bi}
                {...b}
                size="small"
              />
            ))}
          </div>
        )}
      </summary>
      {open && <div className={styles['details__content']}>{props.children}</div>}
    </details>
  );
};

Details.displayName = 'Details';

export default Details;
