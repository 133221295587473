import * as React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import styles from './styles.module.scss';

export interface IArticleCardImage {
  url: string;
  alt: string;
}

export interface IArticleCardProps {
  id: string;
  image: IArticleCardImage;
  headline: React.ReactNode;
  url: string;
  prefix?: React.ReactNode;
  newTab?: boolean;
  loadingType?: 'lazy' | 'eager';
}

const ArticleCard: React.FunctionComponent<IArticleCardProps> = (props) => {
  const linkProps = props.newTab
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {};

  return (
    <div className={styles['article-card']}>
      <Link
        href={props.url}
        className={styles['article-card__image']}
        draggable={false}
        {...linkProps}
        prefetch={false}
      >
        <Image
          draggable={false}
          alt={props.image?.alt}
          src={props.image?.url}
          quality={70}
          priority={false}
          loading={props.loadingType || 'lazy'}
          fill={true}
          sizes="(max-width: 768px) 100vw, (max-width: 1280px) 100px, 280px"
        />
      </Link>
      <div className={styles['article-card__content']}>
        {props.prefix && <div className={styles['article-card__prefix']}>{props.prefix}</div>}
        <div className={styles['article-card__title']}>
          <Link
            href={props.url}
            draggable={false}
            {...linkProps}
          >
            {props.headline}
          </Link>
        </div>
      </div>
    </div>
  );
};

ArticleCard.displayName = 'ArticleCard';

export default ArticleCard;
